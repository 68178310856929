import './App.css';
import React, { Component } from 'react';




let contract;

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Free Mint',
		_publicMintMsg: 'Mint Here',
		_mintStatus: false,
		_cost: 0,
		_wlCost: '',
		_wlMintAmount: '',
		_max_per_wallet: '',
		_owner: ''
	}

	render() {

		return (

			<div class="allWrap">
				
			</div >)
	}
}

export default App;
